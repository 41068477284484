.main-flex {
    display: flex;
    justify-content: space-between;
    max-width: 340px;
    background: #E7E7E7;
    padding: 10px;
    border-radius: 3px;
    /* margin: 50px; */
    align-items: center;
    width: 280px;
}
.pdf-icon ul {
    padding-left: 0;
    margin: 0;
    /* margin-bottom: 0; */
}

.pdf-icon li{
    display: inline-block;
}
.pdf-icon li h5 {
    color: #000000a6;
    font-size: 14px;
    margin-bottom: 0;
    text-transform: capitalize;
    margin: 0;
}
.pdf-icon li i {
    font-size: 20px;
    color: #ff0d0db0;
}
.down-icon i {
    border-radius: 50px;
    border: 1px solid #C1C0C0;
    padding: 7px;
}

/* .pdf-icon {
    display: flex;
    height: 60px;
} */
@import url("https://fonts.googleapis.com/css?family=Manrope:300,400,500,600,700&display=swap&subset=latin-ext");
 [data-theme="purple"] {
	 --theme-color: #9f7aea;
	 --button-color: #9f7aea;
	 --button-bg-color: rgba(159, 122, 234, 0.12);
}
 [data-theme="green"] {
	 --theme-color: #38b2ac;
	 --button-color: #38b2ac;
	 --button-bg-color: rgba(56, 178, 171, 0.15);
}
 [data-theme="orange"] {
	 --theme-color: #ed8936;
	 --button-color: #ed8936;
	 --button-bg-color: rgba(237, 137, 54, 0.12);
}
 .dark-mode {
	 --body-bg-color: #1d1d1d;
	 --theme-bg-color: #27292d;
	 --border-color: #323336;
	 --body-color: #d1d1d2;
	 --active-conversation-bg: linear-gradient(to right, rgba(47, 50, 56, 0.54), rgba(238, 242, 244, 0) 100%);
	 --msg-hover-bg: rgba(47, 50, 56, 0.54);
	 --chat-text-bg: #383b40;
	 --chat-text-color: #b5b7ba;
	 --msg-date: #626466;
	 --msg-message: var(--msg-date);
	 --overlay-bg: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, #27292d 65%, #27292d 100%);
	 --input-bg: #2f3236;
	 --chat-header-bg: linear-gradient(to bottom, #27292d 0%, #27292d 78%, rgba(255, 255, 255, 0) 100%);
	 --settings-icon-color: #7c7e80;
	 --developer-color: var(--border-color);
	 --button-bg-color: #393b40;
	 --button-color: var(--body-color);
	 --input-chat-color: #6f7073;
	 --detail-font-color: var(--input-chat-color);
}
 .blue {
	 background-color: #0086ff;
}
 .purple {
	 background-color: #9f7aea;
}
 .green {
	 background-color: #38b2ac;
}
 .orange {
	 background-color: #ed8936;
}
.chat-msg-img {
	 max-width: 100%;
}

 .app {
	 display: flex;
	 flex-direction: column;
	 background-color: var(--theme-bg-color);
	 max-width: 1600px;
	 height: 100vh;
	 margin: 0 auto;
	 overflow: hidden;
}
 .header {
	 height: 80px;
	 width: 100%;
	 border-bottom: 1px solid var(--border-color);
	 display: flex;
	 align-items: center;
	 padding: 0 20px;
}
 .wrapper {
	 width: 100%;
	 display: flex;
	 flex-grow: 1;
	 overflow: hidden;
}
 .conversation-area, .detail-area {
	 width: 340px;
	 flex-shrink: 0;
}
 .detail-area {
	 border-left: 1px solid var(--border-color);
	 margin-left: auto;
	 padding: 30px 30px 0 30px;
	 display: flex;
	 flex-direction: column;
	 overflow: auto;
}
 .chat-area {
	 flex-grow: 1;
}

.search-bar {
    height: 59px;
    z-index: 3;
    position: relative;
    margin-left: 25px;
}
 .search-bar input {
	 height: 100%;
	 width: 100%;
	 display: block;
	 background-color: transparent;
	 border: none;
	 color: var(--body-color);
	 padding: 0 54px;
	 /* background-image: url("data:image/svg+xml; */
	 background-repeat: no-repeat;
	 background-size: 16px;
	 background-position: 25px 48%;
	 font-family: var(--body-font);
	 font-weight: 600;
	 font-size: 15px;
}
 .search-bar input::placeholder {
	 color: var(--input-chat-color);
}
 .logo {
	 color: var(--theme-color);
	 width: 38px;
	 flex-shrink: 0;
}
 .logo svg {
	 width: 100%;
}
 .user-settings {
	 display: flex;
	 align-items: center;
	 cursor: pointer;
	 margin-left: auto;
	 flex-shrink: 0;
}
 .user-settings > * + * {
	 margin-left: 14px;
}
 .dark-light {
	 width: 22px;
	 height: 22px;
	 color: var(--settings-icon-color);
	 flex-shrink: 0;
}
 .dark-light svg {
	 width: 100%;
	 fill: transparent;
	 transition: 0.5s;
}
 .user-profile {
	 width: 40px;
	 height: 40px;
	 border-radius: 50%;
}
 .settings {
	 color: var(--settings-icon-color);
	 width: 22px;
	 height: 22px;
	 flex-shrink: 0;
}
 .conversation-area {
	 border-right: 1px solid var(--border-color);
	 overflow-y: auto;
	 overflow-x: hidden;
	 display: flex;
	 flex-direction: column;
	 position: relative;
}
 .msg-profile {
	 width: 44px;
	 height: 44px;
	 border-radius: 50%;
	 object-fit: cover;
	 margin-right: 15px;
}
 .msg-profile.group {
	 display: flex;
	 justify-content: center;
	 align-items: center;
	 background-color: var(--border-color);
}
 .msg-profile.group svg {
	 width: 60%;
}
 .msg {
	 display: flex;
	 align-items: center;
	 padding: 20px;
	 cursor: pointer;
	 transition: 0.2s;
	 position: relative;
}
 .msg:hover {
	 background-color: var(--msg-hover-bg);
}
 .msg.active {
	 background: var(--active-conversation-bg);
	 border-left: 4px solid var(--theme-color);
}
 .msg.online:before {
	 content: "";
	 position: absolute;
	 background-color: #23be7e;
	 width: 9px;
	 height: 9px;
	 border-radius: 50%;
	 border: 2px solid var(--theme-bg-color);
	 left: 50px;
	 bottom: 19px;
}
 .msg-username {
	 margin-bottom: 4px;
	 font-weight: 600;
	 font-size: 15px;
}
 .msg-detail {
	 overflow: hidden;
}
 .msg-content {
	 font-weight: 500;
	 font-size: 13px;
	 display: flex;
}
 .msg-message {
	 white-space: nowrap;
	 overflow: hidden;
	 text-overflow: ellipsis;
	 color: var(--msg-message);
}
 .msg-date {
	 font-size: 14px;
	 color: var(--msg-date);
	 margin-left: 3px;
}
 .msg-date:before {
	 content: "•";
	 margin-right: 2px;
}
 .add {
	 position: sticky;
	 bottom: 25px;
	 background-color: var(--theme-color);
	 width: 60px;
	 height: 60px;
	 border: 0;
	 border-radius: 50%;
	 /* background-image: url("data:image/svg+xml; */
	 background-repeat: no-repeat;
	 background-position: 50%;
	 background-size: 28px;
	 box-shadow: 0 0 16px var(--theme-color);
	 margin: auto auto -55px;
	 flex-shrink: 0;
	 z-index: 1;
	 cursor: pointer;
}
 .overlay {
	 position: sticky;
	 bottom: 0;
	 left: 0;
	 width: 340px;
	 flex-shrink: 0;
	 background: var(--overlay-bg);
	 height: 80px;
}
 .chat-area {
	 display: flex;
	 flex-direction: column;
	 overflow: auto;
}
 .chat-area-header {
	 display: flex;
	 position: sticky;
	 top: 0;
	 left: 0;
	 z-index: 2;
	 width: 100%;
	 align-items: center;
	 justify-content: space-between;
	 padding: 20px;
	 background: var(--chat-header-bg);
}
 .chat-area-profile {
	 width: 32px;
	 border-radius: 50%;
	 object-fit: cover;
}
 .chat-area-title {
	 font-size: 18px;
	 font-weight: 600;
}
 .chat-area-main {
	 flex-grow: 1;
}
 .chat-msg-img {
	 height: 40px;
	 width: 40px;
	 border-radius: 50%;
	 object-fit: cover;
}
 .chat-msg-profile {
	 flex-shrink: 0;
	 margin-top: auto;
	 margin-bottom: -20px;
	 position: relative;
}
 .chat-msg-date {
	 position: absolute;
	 left: calc(100% + 12px);
	 bottom: 0;
	 font-size: 12px;
	 font-weight: 600;
	 color: var(--msg-date);
	 white-space: nowrap;
}
 .chat-msg {
	 display: flex;
	 padding: 0 20px 45px;
}
 .chat-msg-content {
	 margin-left: 12px;
	 max-width: 70%;
	 display: flex;
	 flex-direction: column;
	 align-items: flex-start;
}
 .chat-msg-text {
	 background-color:#f1f2f6;
	 padding: 15px;
	 border-radius: 20px 20px 20px 0;
	 line-height: 1.5;
	 font-size: 14px;
	 font-weight: 500;
}
 .chat-msg-text + .chat-msg-text {
	 margin-top: 10px;
}
 .chat-msg-text {
	 color: var(--chat-text-color);
	 overflow-wrap: anywhere;
}
 .owner {
	 flex-direction: row-reverse;
}
 .owner .chat-msg-content {
	 margin-left: 0;
	 margin-right: 12px;
	 align-items: flex-end;
}
.owner .chat-msg-text p{
	margin-top: 0 !important;
    margin-bottom: 0 !important;
}
.owner .chat-msg-text {
    background-color: #01080f;
    color: #fff;
    max-width: 413px;
    border-radius: 20px 20px 0 20px;
    overflow-wrap: anywhere;
}
 .owner .chat-msg-date {
	 left: auto;
	 right: calc(100% + 12px);
}
 .chat-msg-text img {
	 max-width: 300px;
	 width: 100%;
}
 .chat-area-footer {
	 display: flex;
	 border-top: 1px solid var(--border-color);
	 width: 100%;
	 background-color: white;
	 padding: 10px 20px;
	 align-items: center;
	 position: sticky;
	 bottom: 0;
	 left: 0;
}
 .chat-area-footer svg {
	 color: var(--settings-icon-color);
	 width: 20px;
	 flex-shrink: 0;
	 cursor: pointer;
}
 .chat-area-footer svg:hover {
	 color: var(--settings-icon-hover);
}
 .chat-area-footer svg + svg {
	 margin-left: 12px;
}
 .chat-area-footer input {
	 border: none;
	 color: var(--body-color);
	 background-color: var(--input-bg);
	 padding: 12px;
	 border-radius: 6px;
	 font-size: 15px;
	 margin: 0 12px;
	 width: 100%;
}
 .chat-area-footer input::placeholder {
	 color: var(--input-chat-color);
}
.sendbar {
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-right: 0;
    margin: 0 15px;
}
.sendbar button.btnsend {
    border: none;
    background: #f0f8f6;
    position: absolute;
    right: 0;
    width: 40px;
    height: 45px;
}
.sendbar input[type="text"] {
    border: 1px solid #f0f8f6;
    margin: 0 0;
}
 .detail-area-header {
	 display: flex;
	 flex-direction: column;
	 align-items: center;
}
 .detail-area-header .msg-profile {
	 margin-right: 0;
	 width: 60px;
	 height: 60px;
	 margin-bottom: 15px;
}
 .detail-title {
	 font-size: 18px;
	 font-weight: 600;
	 margin-bottom: 10px;
}
 .detail-subtitle {
	 font-size: 12px;
	 font-weight: 600;
	 color: var(--msg-date);
}
 .detail-button {
	 border: 0;
	 background-color: var(--button-bg-color);
	 padding: 10px 14px;
	 border-radius: 5px;
	 color: var(--button-color);
	 display: flex;
	 align-items: center;
	 justify-content: center;
	 font-size: 14px;
	 flex-grow: 1;
	 font-weight: 500;
}
 .detail-button svg {
	 width: 18px;
	 margin-right: 10px;
}
 .detail-button:last-child {
	 margin-left: 8px;
}
 .detail-buttons {
	 margin-top: 20px;
	 display: flex;
	 width: 100%;
}
 .detail-area input {
	 background-color: transparent;
	 border: none;
	 width: 100%;
	 color: var(--body-color);
	 background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 56.966 56.966' fill='%23c1c7cd'%3e%3cpath d='M55.146 51.887L41.588 37.786A22.926 22.926 0 0046.984 23c0-12.682-10.318-23-23-23s-23 10.318-23 23 10.318 23 23 23c4.761 0 9.298-1.436 13.177-4.162l13.661 14.208c.571.593 1.339.92 2.162.92.779 0 1.518-.297 2.079-.837a3.004 3.004 0 00.083-4.242zM23.984 6c9.374 0 17 7.626 17 17s-7.626 17-17 17-17-7.626-17-17 7.626-17 17-17z'/%3e%3c/svg%3e");
	 background-repeat: no-repeat;
	 background-size: 16px;
	 background-position: 100%;
	 font-family: var(--body-font);
	 font-weight: 600;
	 font-size: 14px;
	 border-bottom: 1px solid var(--border-color);
	 padding: 14px 0;
}
 .detail-area input::placeholder {
	 color: var(--detail-font-color);
}
 .detail-changes {
	 margin-top: 40px;
}
 .detail-change {
	 color: var(--detail-font-color);
	 font-family: var(--body-font);
	 font-weight: 600;
	 font-size: 14px;
	 border-bottom: 1px solid var(--border-color);
	 padding: 14px 0;
	 display: flex;
}
 .detail-change svg {
	 width: 16px;
	 margin-left: auto;
}
 .colors {
	 display: flex;
	 margin-left: auto;
}
 .color {
	 width: 16px;
	 height: 16px;
	 border-radius: 50%;
	 cursor: pointer;
}
 .color.selected {
	 background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' stroke='%23fff' stroke-width='3' fill='none' stroke-linecap='round' stroke-linejoin='round' class='css-i6dzq1' viewBox='0 0 24 24'%3E%3Cpath d='M20 6L9 17l-5-5'/%3E%3C/svg%3E");
	 background-size: 10px;
	 background-position: center;
	 background-repeat: no-repeat;
}
 .color:not(:last-child) {
	 margin-right: 4px;
}
 .detail-photo-title {
	 display: flex;
	 align-items: center;
}
 .detail-photo-title svg {
	 width: 16px;
}
 .detail-photos {
	 margin-top: 30px;
	 text-align: center;
}
 .detail-photo-title {
	 color: var(--detail-font-color);
	 font-weight: 600;
	 font-size: 14px;
	 margin-bottom: 20px;
}
 .detail-photo-title svg {
	 margin-right: 8px;
}
 .detail-photo-grid {
	 display: grid;
	 grid-template-columns: repeat(4, 1fr);
	 grid-column-gap: 6px;
	 grid-row-gap: 6px;
	 grid-template-rows: repeat(3, 60px);
}
 .detail-photo-grid img {
	 height: 100%;
	 width: 100%;
	 object-fit: cover;
	 border-radius: 8px;
	 object-position: center;
}
 .view-more {
	 color: var(--theme-color);
	 font-weight: 600;
	 font-size: 15px;
	 margin: 25px 0;
}
 .follow-me {
	 text-decoration: none;
	 font-size: 14px;
	 width: calc(100% + 60px);
	 margin-left: -30px;
	 display: flex;
	 align-items: center;
	 margin-top: auto;
	 overflow: hidden;
	 color: #9c9cab;
	 padding: 0 20px;
	 height: 52px;
	 flex-shrink: 0;
	 position: relative;
	 justify-content: center;
}
 .follow-me svg {
	 width: 16px;
	 height: 16px;
	 margin-right: 8px;
}
 .follow-text {
	 display: flex;
	 align-items: center;
	 transition: 0.3s;
}
 .follow-me:hover .follow-text {
	 transform: translateY(100%);
}
 .follow-me:hover .developer {
	 top: 0;
}
 .developer {
	 position: absolute;
	 color: var(--detail-font-color);
	 font-weight: 600;
	 left: 0;
	 top: -100%;
	 display: flex;
	 transition: 0.3s;
	 padding: 0 20px;
	 align-items: center;
	 justify-content: center;
	 background-color: var(--developer-color);
	 width: 100%;
	 height: 100%;
}
 .developer img {
	 border-radius: 50%;
	 width: 26px;
	 height: 26px;
	 object-fit: cover;
	 margin-right: 10px;
}
 .dark-mode .search-bar input, .dark-mode .detail-area input {
	 background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 56.966 56.966' fill='%236f7073'%3e%3cpath d='M55.146 51.887L41.588 37.786A22.926 22.926 0 0046.984 23c0-12.682-10.318-23-23-23s-23 10.318-23 23 10.318 23 23 23c4.761 0 9.298-1.436 13.177-4.162l13.661 14.208c.571.593 1.339.92 2.162.92.779 0 1.518-.297 2.079-.837a3.004 3.004 0 00.083-4.242zM23.984 6c9.374 0 17 7.626 17 17s-7.626 17-17 17-17-7.626-17-17 7.626-17 17-17z'/%3e%3c/svg%3e");
}
 .dark-mode .dark-light svg {
	 fill: #ffce45;
	 stroke: #ffce45;
}
 .dark-mode .chat-area-group span {
	 color: #d1d1d2;
}
 .chat-area-group {
	 flex-shrink: 0;
	 display: flex;
}
 .chat-area-group * {
	 border: 2px solid var(--theme-bg-color);
}
 .chat-area-group * + * {
	 margin-left: -5px;
}
.custom-file-upload input[type="file"] {
    display: none;
}
 .chat-area-group span {
	 width: 32px;
	 height: 32px;
	 background-color: var(--button-bg-color);
	 color: var(--theme-color);
	 border-radius: 50%;
	 display: flex;
	 justify-content: center;
	 align-items: center;
	 font-size: 14px;
	 font-weight: 500;
}

.participant{
    width: 50% !important;
}
.remote-participants {
    padding: 25px !important;
    margin: 20px 20px 60px 20px;
    background: #ffffff73;
    box-shadow: 0px 0px 5px 0 #CACACA;
    justify-content: space-between !important;
}

.new-join{
		display: flex;
		justify-content: space-between;
}

.local-participant {
    display: flex;
	justify-content: space-between;
	margin-top: 0px !important;
}

.remote-participants .participant{
	width: 50%;

}
.room button {
    width: inherit !important;
    margin-top: 0 !important;
	margin-right: 0;
}
button.custom-btn.mr-0 {
    margin-right: 20px;
}
 @media (max-width: 1120px) {
	 .detail-area {
		 display: none;
	}
}
 @media (max-width: 780px) {
	 .conversation-area {
		 display: none;
	}
    
    .search-bar {
        margin-left: 21px;
        flex-grow: 1;
    }
	 .search-bar input {
		 padding-right: 10px;
	}
}
 
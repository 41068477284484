

@media (min-width:1440px) {
	.container {
		max-width: 1440px;
	}
	.container-xl .container {
		max-width: 1440px;
		padding: 0px;
	}
}

@media screen and (min-device-width: 1200px) and (max-device-width: 1440px) {
	.container {
		max-width: 1280px;
		padding: 0px 40px;
	}
	.container-xl .container {
		max-width: 1350px;
	}
	
}

@media screen and (min-device-width: 1200px) and (max-device-width: 1400px) {
	.main-bn-img img {
		height: 695px;
	}
}

@media screen and (min-device-width: 1400px) and (max-device-width: 1600px) {
	.main-bn-img img {
		height: 600px;
	}
}

@media screen and (min-device-width: 250px) and (max-device-width: 992px) {
	body {
		overflow-x: hidden !important;
	}
	.main-bn-img img 
	{
		display: none;
	}
	.main-bn-feilds 
	{
		width:100%;
		padding-left: 0;
	}
	.navigation .navbar-collapse {
		border-radius: 7px;
		background: #ffffff;
		max-height: 400px;
		left: 0;
		padding: 10px 30px 20px 50px;
		position: absolute;
		z-index: 99;
		top: 50px;
		width: calc(100% + 20px);
		overflow: auto;
		border: medium none;
		-webkit-box-shadow: 0 10px 30px 5px rgba(1, 164, 121, .06);
		-moz-box-shadow: 0 10px 30px 5px rgba(1, 164, 121, .06);
		box-shadow: 0 10px 30px 5px rgba(1, 164, 121, .06);
		left: -10px !important;
		top: 70px;
	}
	.navigation .nav-item {
		margin-left: 0;
		text-align: left;
		width: 100%;
	}
	.nav-link.join-now-btn {
		background: transparent;
		border-radius: 0;
		padding: 0 !important;
		color: #707070 !important;
		border: unset;
		padding-top: 10px !important;
	}
	.navbar-toggler:active,
	.navbar-toggler:focus {
		box-shadow: unset;
	}
	.navbar-toggler {
		border: 0;
		padding: 0px;
	}

	.main-bn-wrapper {
		height: 100%;
		padding: 100px 0;
	}

	.main-bn-content h1 {
		font-size: 28px;
	}

	.main-bn-content h6 {
		font-size: 16px;
	}

	.main-bn-img img {
		height: 100%;
		object-fit: contain;
	}

	.main-bn-img {
		position: relative;
		right: -40px;
		bottom: 0;
	}

	/*.nav-link.login-nav-btn {
		display: none;
	}*/

	.navigation .navbar-collapse {
		top: 85px !important;
	}

	.navigation .navbar-collapse {
		top: 85px !important;
		height: 100vh !important;
		max-height: 100vh;
		padding-left: 25px;
	}

	.navigation .navbar-collapse li {
		padding-bottom: 15px;
	}

	.login-out-nav-btn {
		display: block;
	}

	.main-bn-feilds ul {
		display: block;
	}

	.main-bn-feilds ul li {
		margin-right: 0;
		margin-bottom:15px;
		width: 100% !important;
	}

	.main-bn-feilds {
		padding: 20px;
	}

	.main-bn-feilds p {
		padding-top: 0px;
	}

	.legal-stats ul li {
		display: inline-block;
		padding: 0;
		width: 49.29%;
	}
	.legal-stats ul li.w-50 {
		width: 100% !important;
	}

	.legal-stats .mb-hide {
		display: none;
	}
	.lp-box-wrp {
		padding:0px;
	}
	.membership-plan-box.active {
		transform: scale(1);
	}
	.footer-print {
		display: none;
	}
	.newsletter-content {
		padding-top: 25px;
	}
	.premium-expert-img {
		display: none;
	}

	.auth-modal-artwork {
		display: none;
	}

	.get-started-buttons button {
		width: 100%;
	}

	.auth-modal-content ul li {
		width: 100%;
		padding: 0px !important;
		margin-bottom: 15px;
	}

	.auth-modal-content ul {
		display: block !important;
	}

	.breadcrumb-search input {
		width: 100%;
	}

	.breadcrumb-search {
		justify-content: start;
		margin-top: 15px;
	}

	.breadcrumb-content ul li {
		margin-bottom: 5px;
	}

	.error-img img {
		object-fit: cover;
		width: auto;
		height: 150px;
	}

	.filter-listing ul {
		float: left;
	}

	.filter-listing ul li {
		margin-top: 15px;
		width: 100%;
		margin-right: 15px;
		padding-right: 25px;
	}

	.filter-listing h3 {
		margin-bottom: 15px;
	}

	.exp-listing-con-filter p {
		text-align: center;
	}

	.rec-filter-select {
		justify-content: center;
	}

	.common-pagination ul li:first-child {
		margin-right: 5px;
	}

	.common-pagination ul li a {
		height: 35px !important;
		width: 35px !important;

	}

	.exp-profile-detail-row ul {
		display: block;
	}

	.exp-profile-detail-row ul li {
		border-right: 1px solid #D6D6D6;
		padding-right: 15px;
		margin-right: 25px;
		text-align: left;
		min-height: 65px;
		display: flex;
		align-items: center;
	}

	.exp-profile-detail-row ul li {
		border-right: unset;
		padding-right: 0;
		margin-right: 0;
		text-align: left;
		min-height: 65px;
		display: flex;
		align-items: center;
		border-bottom: 1px solid #D6D6D6;
		margin-bottom: 15px;
		padding-bottom: 15px;
	}

	.exp-communicate-btn-row {
		display: block;
		padding: 35px 0px;
	}

	.exp-communicate-btn-row button {
		width: 100%;
		margin-bottom: 15px;
	}

	.exp-profile-detail-row {
		border-bottom:unset;
		margin-bottom: 0px;
		padding-bottom: 0px;
	}

	.continue-booking-btn {
		padding: 15px 0px;
	}

	.book-app-box input {
		margin-bottom: 15px;
	}

	.book-app-box select {
		margin-bottom: 15px;
	}

	.breadcrumb-content h2 {
		font-size: 26px;
	}

	.sidebar-toggle {
		display: block;
	}

	.sidebar-wrapper {
		width: 100%;
		top: 125px;
		display: none;
	}

	.admin-content-wrapper {
		width: 100%;
		margin-left: 0;
		padding: 15px;
		margin-top: 150px;
	}

	.appointment-tabs .nav-item button {
		margin-right: 15px;
		margin-bottom: 15px;
	}

	.appointment-tabs ul {
		justify-content: center;
	}

	.common-table-wrapper {
		margin-top: 10px;
	}

	.app-agenda-box {
		height: auto;
		margin-bottom: 15px;
	}

	.appointment-details-modal-wrp {
		display: block;
	}

	.appointment-details-modal-wrp p {
		text-align: center;
	}

	.appointment-details-modal-wrp h3 {
		margin-bottom: 15px;
	}

	.app-user-wrp {
		display: block;
		text-align: center;
		margin-bottom: 15px;
	}

	.wallet-recharge-wrp {
		display: block;
	}

	.wallet-rec-button {
		width: 100%;
		text-align: center;
		padding-top: 15px;
		display: flex;
		justify-content: center;
	}


}



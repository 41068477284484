.keypad-button {
  border: none;
  background-color: #000;
  color: #fff;
  width: 34%;
  height: 2.5em;
  font-size: 14px;
  line-height: 1;
  padding: 0;
}

.keypad-button.green {
  background-color: #36d576;
  color: #fff;
}

.keypad-button.red {
  background-color: #f22f46;
  color: #fff;
}

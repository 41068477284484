/*  */

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
  monospace;
}

.hidden {
  display: none;
}

/* #root {
    height: 100vh;
}  */

.conversations-window-wrapper {
  background-color: #f0f2f5;
  position: fixed;
  right:0;
  width:inherit;
  margin-top: 3px;
}
.room-detail 
{
  font-size: 26px;
  padding-left:77px;
}
.conversations-window-container {
  position: relative;
  max-width: 1268px;
  height: calc(100% - 16px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto 8px auto;
  border:1px solid #EAEAEA;
}


 
  
  .ant-layout-header {
    background: #333e5a;
    color: #fff;
    text-align: center;
    flex-grow: 0;
    margin-bottom: 2em;
  }
  

  
  .app {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
  }
  
  main {
    background: #ffffff;
    flex-grow: 1;
  }
  
  .Form {
    max-width: 300px;
    margin: 0 auto;
  }
  
  
  .room {
    position: relative;
  }
  .room button {
    /* position: absolute; */
    top: 0;
    color: #fff;
    border: none;
    width: 30%;
    padding: 10px;
    /* border-radius: 4px; */
    margin-top: 4px;
    right: 20px;
    background-color: #000;
    margin-right:65px;

  }
  .room > h3 {
    text-align: center;
    font-weight: 300;
    margin-bottom: 1em;
  }
  
  .local-participant {
    text-align: center;
    margin-bottom: 2em;
    margin-top: 52px;
  }
  .remote-participants {
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
    padding: 0 2em 2em;
    margin-top:22px;
  }
  .participant {
    background: #095D52;
    padding: 10px;
    border-radius: 6px;
    display: inline-block;
    margin-right: 10px;
  }
  .participant:last-child {
    margin-right: 0;
    background:url('/public/assets/img/breadcrumb-bg.png');
    background-position:bottom;
    width:84%;
  }
  .participant h3 {
    text-align: center;
    padding-bottom: 0.5em;
    color: #fff;
    font-size: 19px;
  }
  
  video {
    width: 100%;
    display: block;
    margin: 0 auto;
    border-radius: 6px;
  }
  
  footer {
    background: #333e5a;
    color: #fff;
    text-align: center;
    flex-grow: 0;
    padding: 1em 0;
  }
  
  footer a {
    color: #fff;
  }
.custom-form {
  width: 60%;
}

.react-calendar__navigation button:disabled {
  background-color: #000 !important;
}
.custm-loader-legal-practice {
  justify-content: center;
  display: flex;
  width: 100%;
}
.membership-plan-box {
  transition: 0.5s ease;
}
.membership-plan-box:hover h3,
.membership-plan-box:hover h5,
.membership-plan-box:hover h4,
.membership-plan-box:hover h6,
.membership-plan-box:hover p {
  color: #ffffff;
}
.membership-plan-box:hover {
  transform: scale(1.1);
  background: #095d52;
}

.membership-plan-box:hover button.btn {
  color: #ffffff;
  border-color: #ffffff;
}

@media only screen and (max-width: 992px) {
  .custom-form {
    width: 100%;
    float: left;
  }
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
.custom-loader 
{
  height: 15px;
	width: 15px;
	border: 2px solid #fff;
	margin-left: 10px
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}
.react-calendar {
  margin: auto;
  background: #e4f7f3 !important;
  border: none !important;
 
}
.App-link {
  color: #61dafb;
}
audio{
  width: 30px !important;
  height: 20px;
  background-image: url("./logo.svg");
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


  
  #ChatWindow {
    border: 1px solid #eee;
    border-radius: 8px;
    background: #fff;
  }
  
  #SelectedConversation {
    height: 100%;
    display: flex;
    flex-direction: column;
  }
  
  #participants {
    font-family: Monaco;
    size: 12pt;
  }
  
  #participants, #messages {
    margin-top: 1em;
    margin-bottom: 1em;
    margin-left: 0.5em;
    margin-right: 0.5em;
    height: 100%;
    overflow: scroll;
  }
  
  #OpenChannel {
    display: flex;
    flex-basis: 100%;
    flex-direction: column;
  }
  
  /* .nav-pills .nav-item {
    border: 1px solid #dddddd;
    border-radius: 8px;
    padding-left: 0.3em;
    padding-right: 0.3em;
  } */
  
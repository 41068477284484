.call {
  text-align: center;
}
.status {
  text-align: center;
  background: #f4f4f6;
  display: block;
  width: 200px;
  padding: 0.5em;
  border-radius: 6px;
  margin: 2em auto 0;
}
.call-wrap1 {
  box-shadow: gray 0px 3px 8px;
  padding: 50px 20px;
   width: 60%;
  height:400px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: auto;
  margin-top: 112px;
  text-align: center;
}
.keypad-button.green {
  background-color: #095D52;
  color: #fff;
  width: 78px;
  height: 76px;
  border-radius: 50%;
  font-size: 24px;
}
.call-wrap1 .btn-danger 
{
  margin-left: 10px;
}
.leave {
  text-align: center;
  background: #8C0606;
  color:#fff;
  display: block;
  width: 200px;
  padding: 0.5em;
  border-radius: 6px;
  margin: 1em auto 0;
}
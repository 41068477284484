

/* Basic Css  */

html,
body {
	scroll-behavior: smooth;
}
a{
	text-decoration: none !important;
}
body {
	overflow-x: hidden !important;
	font-weight: 400;
	transition: all .4s ease;
	-webkit-transition: all .4s ease;
	letter-spacing: 0.2px;
	font-family: 'Poppins', sans-serif;
	transition: all 0.3s ease-in-out;
}
::selection{
	background-color: #000000;
	color: #ffffff;
}
.blackok{
	background-color: #eff8f6 !important;
}
:hover {
	transition: all .4s ease;
	-webkit-transition: all .4s ease;
}

button:focus {
	outline: none;
}

a {
	text-decoration: none;
}

a:hover {
	text-decoration: none;
	color: #095d52;
}

section,
header,
footer {
	width: 100%;
	float: left;
}

.no-scroll {
	overflow: hidden !important;
}

.btn:hover {
	transition: all ease 0.4s;
}

::selection {
	color: #000;
	background: #fff;
}

.headersticky {
	position: fixed;
	top: 0;
	right: 0;
	left: 0;
	z-index: 99999;
	box-shadow: 0px 0px 5px 0 #cacaca;
	background: #fff;
}

.btn:focus {
	box-shadow: unset !important;
}

.form-control:focus,
.form-select:focus {
	box-shadow: unset !important;
	border-color: #000 !important;
}

.form-check-input:checked {
	background-color: #000;
	border-color: #000;
}

.form-check-input:focus {
	border-color: #000;
	outline: 0;
	box-shadow: 0 0 0 .25rem rgb(229, 242, 238);
}


/* homepage css start */

.navigation {
	background: #fff;
    padding: 5px;
    position: sticky; 
    width: 100%;
    top: 0;
    left: 0;
    right: 0;
    z-index: 9;
	box-shadow: 0px 0px 5px 0 #cacaca;
}

.navigation .logo-img {
	height: 60px;
	object-fit: contain;
}

.navigation .navbar-nav {
	align-items: center;
}

.navigation .nav-item {
	margin-left: 35px;
}

.navigation .nav-item .nav-link {
	color: #000;
	font-size: 14px;
	white-space: nowrap;
	font-weight: 500;
}

.navigation .nav-item .nav-link.active {
	color: #165d51;
}

.navigation .nav-item .nav-link:hover {
	color: #165d51;
}

.main-bn-wrapper {
	background: url("/public/assets/img/banner-img.png");
	height: calc( 100vh - 96px);
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
	position: relative;
	display: flex;
	align-items: center;
	z-index:-1;
} 

.nav-link.login-nav-btn {
	background: #000;
	color: #fff !important;
	padding: 8px 24px !important;
	border-radius: 4px;
}

.main-bn-img {
	position: absolute;
	right: 0;
	bottom: 0;
}

.main-bn-img img {
	height: 800px;
}

.main-bn-content h1 {
	color: #fff;
	line-height: 130%;
	font-weight: 700;
	text-transform: capitalize;
	margin-bottom: 20px;
	font-size: 48px;
}

.main-bn-content h6 {
	font-size: 22px;
	line-height: 150%;
	color: #fff;
	font-weight: 300;
}

.login-out-nav-btn {
	background: #000;
	color: #fff;
	padding: 5px 15px;
	font-size: 14px;
	border-radius: 2px;
	position: relative;
	right: -30px;
	display: none;
}

.main-bn-feilds {
	background: rgba(255,255,255,0.35);
	margin-top: 35px;
	border: 1px solid rgba(255,255,255,0.35);
	float: right;
	width: 131%;
	padding-left: 31% !important;
	padding: 35px;
	padding-bottom: 15px;
}

.main-bn-feilds ul {
	list-style: none;
	padding-left: 0px;
	display: flex;
	justify-content: start;
	margin-bottom: 0px;
}

.main-bn-feilds ul li {
	margin-right: 30px;
	position: relative;
	width: 60%;
}
.main-bn-feilds ul li:first-child {
	width: 40%;
}
.main-bn-feilds ul li{
	width: 100% !important;
	cursor: pointer;
}
.main-bn-feilds ul li input {
	height: 45px;
	font-size: 14px;
	color: #000;
	border-radius: 0;
	padding-right: 50px;
	border-color: #fff;
}

.main-bn-feilds ul li input::placeholder {
	font-size: 14px;
	color: #000;
}

.main-bn-feilds ul li select {
	height: 45px;
	font-size: 14px;
	color: #000;
	border-radius: 0;
	border-color: #fff;
}

.main-bn-feilds ul li button {
	background: #000;
	border-radius: 0px;
	position: absolute;
	top: 0;
	right: 0;
	height: 45px;
	width: 45px;
	display: flex;
	align-items: center;
	justify-content: center;
}

.main-bn-feilds ul li button img {
	height: 20px;
	object-fit: contain;
}

.main-bn-feilds p {
	color: #fff;
	font-size: 14px;
	padding-top: 25px;
	margin-bottom: 0px;
	font-weight: 500;
}

.main-bn-feilds p span {
	font-style: italic;
	font-weight: 300;
}

/* legal practice area */

.legal-area-wrp {
	padding: 100px 0px;
}

.legal-stats ul {
	list-style: none;
	padding-left: 0px;
	margin-bottom: 0px;
}

.legal-stats ul li {
	display: inline-block;
	padding: 0;
	width: 16.29%;
}

.legal-stats ul li.w-50 {
	width: 49.5% !important;
}

.lp-box-wrp {
	background: #E4F7F3;
	height: 150px;
	padding: 25px;
	display: flex;
	align-items: center;
	justify-content: center;
	-webkit-box-shadow: -1px 15px 40px -10px rgba(0,0,0,0.32);
	-moz-box-shadow: -1px 15px 40px -10px rgba(0,0,0,0.32);
	box-shadow: -1px 15px 40px -10px rgba(0,0,0,0.32);
	margin-bottom: 2.5px;
	transform: scale(1);
	transition: all .4s ease;
	-webkit-transition: all .4s ease;
}

.lp-box-wrp:hover {
	transform: scale(1.035);
	transition: all .4s ease;
	-webkit-transition: all .4s ease;
}

.lp-box-wrp.no-shadow:hover {
	transform: scale(1);
	transition: all .4s ease;
	-webkit-transition: all .4s ease;
}

.lp-box-wrp h3 {
	font-size: 32px;
	font-weight: 600;
	color: #000;
}

.lp-box-wrp p {
	font-size: 16px;
	margin-bottom: 0px;
	color: #676767;
	font-weight: 300;
}

.lp-box-wrp img {
	height: 60px;
	margin-bottom: 15px;
	object-fit: contain;
}

.lp-box-wrp h5 {
	font-size: 14px;
	font-weight: 500;
	color: #000;
}

.lp-box-wrp-empty {
	background: transparent;
}

.lp-box-wrp.lp-white-bg {
	background: #fff;
}

.legal-stats .mb-hide {
	display: inline-block;
}

.no-shadow {
	box-shadow: unset !important;
}

.common-head h3 {
	font-size: 48px;
	font-weight: 600;
	margin-bottom: 0px;
	color: #000;
}

.common-head {
	margin-bottom: 30px;
}

.our-experts-wrp {
	padding-bottom: 100px;
}

.star-rating span {
	color: rgba(255,255,255,0.25);
}

.star-rating span.checked {
	color: #E5DF42 !important;
}

.expert-box-wrp {
	background: #095D52;
	overflow: hidden;
	border-radius: 12px;
}

.expert-box-wrp img {
	height: 150px;
	object-fit: cover;
	width: 100% !important;
	object-position: top;
}

.expert-box-wrp .active-dot {
	height: 15px;
	width: 15px;
	display: block;
	background: #00FF1D;
	border-radius: 10px;
	position: absolute;
	top: 15px;
	left: 15px;
}

.expert-box-wrp h5 {
	color: #fff;
	padding-top: 10px;
	font-size: 16px;
	margin-bottom: 5px;
}

.expert-box-wrp h6 {
	font-size: 14px;
	color: #fff;
	font-weight: 300;
}

.expert-box-wrp p {
	color: #fff;
	font-size: 12px;
	font-weight: 300;
	padding-top: 10px;
	margin-bottom: 0px;
}

.expert-box-wrp button {
	width: 100%;
	background: #fff;
	border: 2px solid #fff;
	margin-top: 20px;
	font-size: 14px;
	padding: 10px 0px;
	color: #000;
	border-radius: 6px;
}

.expert-box-wrp button:hover {
	color: #fff;
	background:transparent;
}

.our-experts-wrp .owl-nav span {
	display: none;
}

.our-experts-wrp .owl-nav .owl-prev::before {
	content: "";
	background: url("/public/assets/img/owl-prev-icon.png");
	background-repeat: no-repeat;
	position: absolute;
	height: 40px;
	width: 80px;
	background-size: contain;
}

.our-experts-wrp .owl-nav .owl-next::after {
	content: "";
	background: url("/public/assets/img/owl-next-icon.png");
	background-repeat: no-repeat;
	position: absolute;
	height: 40px;
	width: 80px;
	background-size: contain;
}

.our-experts-wrp .owl-nav .owl-next {
	margin-left: 100px !important;
}

.our-experts-wrp .owl-nav {
	text-align: right !important;
	margin-right: 70px;
	position: absolute;
	top: -100px;
	right: 0;
}

.our-experts-wrp.pre-exp-listing {
	padding-bottom: 0px !important;
	padding-top: 100px;
}

.common-head p {
	font-size: 18px;
	padding-top: 15px;
	color: #676767;
	font-weight: 300;
}

.top-legal-box-wrp p img {
	width: 10px !important;
}

.top-legal-box-wrp p img {
	width: 10px !important;
	margin-right: 5px;
}

.top-legal-box-wrp p {
	display: flex;
	align-items: center;
	justify-content: center;
}

.top-legal-box-wrp {
	text-align: center;
	background: #E4F7F3;
	padding: 50px;
	-webkit-box-shadow: -1px 15px 40px -10px rgba(0,0,0,0.32);
	-moz-box-shadow: -1px 15px 40px -10px rgba(0,0,0,0.32);
	box-shadow: -1px 15px 40px -10px rgba(0,0,0,0.32);
}

.top-legal-user-img img {
	height: 100px;
	width: 100px !important;
	margin: 0 auto;
	padding: 10px;
}
.image-radius img{border-radius: 50% !important;}
.top-legal-user-img {
	margin-bottom: 15px;
	background: url("/public/assets/img/legal-expert-cbg.png");
	background-repeat: no-repeat;
	background-size: 100px;
	margin: 0 auto;
	background-position: center;
	width: 200px;
    margin: 0 auto;
	margin-bottom: 25px !important;
}

.top-legal-box-wrp p {
	display: flex;
	align-items: center;
	justify-content: center;
	margin-bottom: 5px;
	margin: 0 auto;
	font-size: 14px;
	font-weight: 400;
}

.top-legal-box-wrp .star-rating {
	margin-bottom: 10px;
	margin-top: 5px;
}

.top-legal-wrp {
	padding-bottom: 100px;
}

.top-legal-owl {
	margin-top: 10px;
}

.top-legal-box-wrp h4 {
	font-size: 16px;
	font-weight: 600;
}

.top-legal-box-wrp p:last-child {
	border-top: 1px solid #E6E6E6;
	margin-top: 10px;
	padding-top: 10px;
}

.top-legal-owl .owl-item:nth-child(odd) .top-legal-box-wrp {
	background: #fff !important;
}  
  
  /* .top-legal-owl .owl-item:nth-child(even) .top-legal-box-wrp {
	background: red !important;
  }
   */

   .top-legal-owl .owl-nav span {
	display: none;
}

.top-legal-owl .owl-nav .owl-prev::before {
	content: "";
	background: url("/public/assets/img/owl-prev-icon.png");
	background-repeat: no-repeat;
	position: absolute;
	height: 40px;
	width: 80px;
	background-size: contain;
}

.top-legal-owl .owl-nav .owl-next::after {
	content: "";
	background: url("/public/assets/img/owl-next-icon.png");
	background-repeat: no-repeat;
	position: absolute;
	height: 40px;
	width: 80px;
	background-size: contain;
}

.top-legal-owl .owl-nav .owl-next {
	position: absolute;
	top: 40%;
	right: 70px;
}

.top-legal-owl .owl-nav .owl-prev {
	position: absolute;
	top: 40%;
	left: 0;
}

.work-gets-done-wrp {
	padding: 100px 0px;
	background: url("/public/assets/img/how-work-bg.png");
	background-size: cover;
	background-position:center;
	background-repeat:no-repeat;
}

.work-gets-done-wrp .common-head {
	text-align: center;	
}

.work-gets-done-wrp .common-head h3, .work-gets-done-wrp .common-head p {
	color: #fff !important;
} 

.hwg-box {
	border: 1px solid #dfdfdf;
    text-align: center;
    margin-top: 15px;
    vertical-align: middle;
    padding: 40px 25px;
    display: flex;
    border-radius: 15px;
    background: #ffffff40;
    color: black !important;
    min-height: 380px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
	transition: all 0.3s ease-in-out;
}
.hwg-box:hover{
	transform: translateY(-10px);
	box-shadow: 0px 2px 15px #dfdfdf;
}

.hwg-box img {
	height: 100px;
	object-fit: contain;
	margin-bottom: 15px;
}

.hwg-box h4 {
	font-size: 24px;
	color: #fff;
	font-weight: 500;
}

.hwg-box p {
	font-size: 18px;
	color: #fff;
	font-weight: 300;
}

.membership-plan-wrp {
	padding: 100px 0px;
	position: relative;
}

.mem-plan-layer img {
	width: 100%;
	position: absolute;
	bottom: -100px;
	z-index: -1;
}

.membership-plan-box {
	background: #E6EFEE;
	padding: 50px;
	text-align: left;
	border-radius: 25px;
	box-shadow: -4px 12px 29px 1px rgba(145,145,145,0.53);
	-webkit-box-shadow: -4px 12px 29px 1px rgba(145,145,145,0.53);
	-moz-box-shadow: -4px 12px 29px 1px rgba(145,145,145,0.53);
	position: relative;
	overflow: hidden;
	transform: scale(0.9);
}

.membership-plan-box .side-indicator {
	background: #095D52;
	height: 80px;
	width: 10px;
	position: absolute;
	left: 0;
}

.membership-plan-box h3 {
	font-size: 24px;
	font-weight: 600;
	color: #000;
	margin-bottom: 15px;
}

.membership-plan-box h5 {
	font-size: 18px;
	font-weight: 500;
	color: #000;
	margin-bottom: 20px;
}

.membership-plan-box h5 img {
	height: 35px;
	object-fit: contain;
	position: relative;
	top: -3px;
	margin-right: 5px;
}

.membership-plan-box h4 {
	font-size: 26px;
	font-weight: 300;
	position: relative;
	color: #000;
}


.membership-plan-box h4 b {
	font-weight: 700;
	font-size: 28px;
}

.membership-plan-box h6 {
	font-size: 14px;
	margin-top: 25px;
	color: #3E3E3E;
	margin-bottom: 5px;
}

.membership-plan-box button {
	background: transparent;
	border: 1.5px solid #000;
	margin-top: 15px;
	width: 100%;
	font-size: 14px;
	padding: 10px 15px;
	color: #000;
	font-weight: 600;
}

.membership-plan-box button:hover {
	background: #000;
	color: #fff;
}

.membership-plan-box h4 span {
	background: red;
	width: 80px;
	height: 3px;
	position: absolute;
	top: 14px;
	left: 0;
}

.bv-tag {
	background: #FFC400;
	width: 150px;
	text-align: center;
	padding: 4px 0px;
	font-size: 12px;
	font-weight: 600;
	position: absolute;
	right: -40px;
	top: 20px;
	transform: rotate(40deg);
}

.membership-plan-box.active {
	background: #095D52;
	transform: scale(1.1);
}

.membership-plan-box.active .side-indicator {
	background: #CEEBF6;
}

.membership-plan-box.active h3, .membership-plan-box.active h5, .membership-plan-box.active h4, .membership-plan-box.active h6, .membership-plan-box.active p {
	color: #fff;
}

.membership-plan-box.active button {
	border-color: #fff;
	color: #fff;
}

.membership-plan-box.active button:hover {
	background: #fff;
	color: #095D52;
}

.membership-plan-box.active h4 {
	font-size: 30px;
}

.membership-plan-box.active h4 span {
	top: 18px;
	width: 120px;
}

.premium-expert-wrp {
	background: rgb(43,254,214);
	background: linear-gradient(236deg, rgba(43,254,214,1) 0%, rgba(9,94,82,1) 100%);
	margin-top: 100px;
	height: 500px;
	position: relative;
}

.premium-expert-img {
	position: absolute;
	bottom: 0px;
}

.premium-expert-content {
	height: 500px;
	display: flex;
	align-items: center;
}

.premium-expert-img img {
	height: 650px;
}

.premium-expert-content h1 {
	color: #fff;
	font-size: 38px;
	font-weight: 600;
	margin-bottom: 25px;
}

.premium-expert-content p {
	font-size: 16px;
	color: #fff;
	line-height: 160%;
	font-weight: 300;
}

.premium-expert-content button {
	background: #fff;
	padding: 12px 24px;
	font-weight: 500;
	color: #000;
	font-size: 16px;
	margin-top: 10px;
	border-radius: 10px;
	border: 1px solid #fff;
}

.premium-expert-content button:hover {
	background: transparent;
	color: #fff;
}

.client-feature-wrp {
	padding: 100px 0px;
}

.client-feature-img img {
	height: 100px;
	width: 100px !important;
	object-fit: cover;
	border-radius: 100px;
	margin: 0 auto;
	padding: 10px;
}

.client-feature-img {
	margin-bottom: 15px;
	background: url("/public/assets/img/legal-expert-cbg.png");
	background-repeat: no-repeat;
	background-size: 100px;
	margin: 0 auto;
	background-position: center;
	width: 118px;
	margin: 0 auto;
	margin-left: 0;
	margin-bottom: 25px !important;
}

.client-feature-box {
	text-align: start;
    padding: 20px;
    border: 1px solid #dfdfdf;
    box-shadow: 0 2px 15px #dfdfdf;
    border-radius: 25px;
}

.client-feature-content p {
	font-size: 18px;
	color: #676767;
	line-height: 150%;
	font-weight: 300;
}

.client-feature-content h5 {
	font-size: 18px;
	font-weight: 600;
	color: #000000;
	padding-top: 10px;
}

.client-feature-owl .owl-nav span {
	display: none;
}

.client-feature-owl .owl-nav .owl-prev::before {
	content: "";
	background: url("/public/assets/img/owl-prev-icon.png");
	background-repeat: no-repeat;
	position: absolute;
	height: 40px;
	width: 80px;
	left: 0px;
	background-size: contain;
}

.client-feature-owl .owl-nav .owl-next::after {
	content: "";
	background: url("/public/assets/img/owl-next-icon.png");
	background-repeat: no-repeat;
	position: absolute;
	height: 40px;
	width: 80px;
	background-size: contain;
}

.client-feature-owl .owl-nav .owl-next {
	position: absolute;
	top: 40%;
	right: 26px;
}

.client-feature-owl .owl-nav .owl-prev {
	position: absolute;
	top: 40%;
	left: -50px;
}

.client-feature-owl .owl-dot span {
	width: 20px !important;
	border-radius: 0px !important;
	height: 3px !important;
	background:#CEEBF6 !important; 
}


.client-feature-owl .owl-dot.active span {
	background:#000 !important; 
}

.newsletter-wrp {
	padding: 75px 0px;
	background:url("/public/assets/img/newsletter-bg.png") no-repeat center;
	background-size: cover;
}

.newsletter-feild-box {
	background: rgba(255,255,255,0.35);
	border: 1px solid rgba(255,255,255,0.35);
	float: right;
	width: 141%;
	padding-left: 41% !important;
	padding: 35px;
}

.newsletter-feild-box input {
	height: 50px;
	font-size: 16px;
	color: #000;
	border-radius: 0;
	padding-right: 120px;
	border-color: #fff;
}

.newsletter-feild-box input::placeholder {
	color: #000;
}

.newsletter-feild-box button {
	position: absolute;
	top: 7.5px;
	right: 7.5px;
	background: #000;
	height: 35px;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 12px;
	color: #fff;
	border-radius: 0;
	width: 100px;
	border: 2px solid #000;
}

.newsletter-feild-box button:hover {
	background:transparent ;
	color: #000;
}

.newsletter-content h1 {
	font-size: 38px;
	color: #fff;
	font-weight: 600;
	margin-bottom: 10px;
}

.newsletter-content p {
	font-size: 16px;
	color: #fff;
	font-weight: 400;
	margin-bottom: 0px;
}

.newsletter-content {
	height: 100%;
	display: flex;
	align-items: center;
}

.footer-wrp {
	background: rgb(43,254,214);
	background: linear-gradient(236deg, rgba(43,254,214,1) 0%, rgba(9,94,82,1) 100%);
	padding-top: 63px;
	position: relative;
}

.footer-link-list h3 {
	color: #fff;
	font-size: 24px;
	font-weight: 500;
	border-bottom: 2px solid #fff;
	/* width: 50%; */
	padding-bottom: 11px;
	margin-bottom: 30px;
}

.footer-link-list ul {
	list-style: none;
	padding-left: 0;
	margin-top: 15px;
	margin-bottom: 0px;
}

.footer-link-list ul li a {
	color: #fff;
	padding-bottom: 15px;
	display: block;
	font-weight: 300;
	font-size: 20px;
}
.footer-link-list ul li a img
{
   padding-right:5px;
}
.reach-us
{
	text-align: center;
}
.page-fourzero-banner{
	background-image: url(../../../public/assets/img/404-img.png);
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	height: 300px;
}
.cancle-bg {
    background-image: url(../../../public/assets/img/ohhhh.png);
    background-size: contain;
    margin-top: 30px;
}
.success-bg {
    background-image: url(../../../public/assets/img/succes.png);
    background-size: contain;
    margin-top: 30px;
}
.fourzero-content {
	padding: 50px 0;
	text-align: center;
	position: relative;
}
.fourzero-content h4 {
	font-size: 26px;
	margin-bottom: 15px;
}
.fourzero-content p {
	font-size: 18px;
	margin-bottom: 20px;
}
.fourzero-content .gohome {
	display: flex;
	align-items: center;
	justify-content: center;
}
.fourzero-content .gohome a {
	color: #002543;
	border: 1px solid #002543;
	padding: 12px 40px;
	transition: .5s ease;
}
.fourzero-content .gohome a:hover {
	background: #002543;
	color: #ffffff;
}

@media(max-width:991px)
{
	.reach-us{
		text-align: left;
	}
	.reach-us h3
	{
		width:50% !important;
	}
	.footer-link-list h3
	{
		margin-top: 30px;
	}
}
.social-media-icons li
{
	float: left;
	width:25%;
}
.social-media-icons li img{
	width:50px;
}
.footer-bottom {
	padding-top: 40px;
	padding-bottom: 10px;
	position: relative;
	z-index: 999;
}

.footer-bottom p {
	font-size: 14px;
	color: #fff;
	margin-bottom: 5px;
	font-weight: 500;
}

.footer-bottom p a {
	color: #fff;
}

.footer-print {

	position: absolute;
	left: 0;
	right: 0;
	margin-left: 0 auto;
	top: 25px;
	text-align: right;
	padding-right: 15%;
}

.footer-print img {
	height: 350px;
}

.footer-link-list {
	position: relative;
	z-index: 99;
}  
@media (max-width: 767px){
	.page-fourzero-banner {
		height: 180px;
		background-size: conatin;
	}
	.fourzero-content {
		padding: 30px 15px;
		text-align: center;
		position: relative;
	}
	.fourzero-content h4 {
		font-size: 20px;
		margin-bottom: 10px;
	}
	.fourzero-content p {
		font-size: 14px;
		margin-bottom: 15px;
	}
	.fourzero-content .gohome a {
		color: #002543;
		border: 1px solid #002543;
		padding: 10px 20px;
		transition: .5s ease;
		font-size: 14px;
	}
}

@media (max-width:1024px)
 {
	.footer-print {

		padding-right: 0%;
	}
}
/* Authentication Modal Css Start */

.authentication-modal .modal-content {
	border-radius: 15px;
}

.authentication-modal .modal-body {
	padding: 0;
	overflow: hidden;
}

.auth-modal-artwork {
	background: #E4F7F3;
	padding: 100px 50px;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
}

.auth-modal-wrp {
	border-radius: 15px;
	overflow: hidden;
}

.auth-modal-content {
	text-align: center;
	padding: 25px 50px;
	padding-bottom: 25px;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
}

.auth-modal-logo img {
	height: 80px;
	object-fit: contain;
	margin-bottom: 25px;
}

.auth-modal-content h2 {
	font-size: 22px;
	font-weight: 600;
}

.auth-modal-content label {
	font-size: 14px;
	margin-bottom: 10px;
	font-weight: 400;
}

.auth-modal-content input {
	height: 45px;
	background: #F5F5F5;
	border: 1px solid #E3E3E3;
	border-radius: 5px;
	margin-bottom: 15px;
	font-size: 14px;
}

.auth-modal-content input:focus {
	border: 1px solid #E3E3E3;
	background: #F5F5F5;
}

.auth-input-wrp {
	text-align: left;
	padding-top: 25px;
}

.btn.auth-main-btn {
	background: #000;
	border: 1px solid #000;
	width: 100%;
	color: #fff;
	height: 45px;
	display: flex;
	align-items: center;
	justify-content: center;
	margin-top: 25px;
	margin-bottom: 25px;
	border-radius: 5px;
}

.btn.auth-main-btn:hover {
	background: transparent;
	border: 1px solid #000;
	width: 100%;
	color: #000 !important;
}

.auth-modal-content ul li button {
	width: 100%;
	background: #F5F5F5;
	height: 45px;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 5px;
	margin: 0px 5px;
}

.auth-modal-content ul {
	list-style: none;
	padding-left: 0px;
	margin-bottom: 50px;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 13px ;
}

.auth-modal-content ul li {
	margin: 0px 0;
	width: 50%;
}

.auth-modal-content ul li button img {
	height: 16px;
	margin-right: 8px;
}

.auth-modal-content h5 {
	font-size: 12px;
	font-weight: 400;
	color: #000;
	padding-top: 25px;
}

.auth-modal-content h5 a {
	color: #0045FF !important;
}

.auth-modal-content p {
	font-size: 14px;
	color: #000;
	font-weight: 400;
}

.gender-buttons {
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
}

.gender-buttons button {
	width: 50%;
	border: 2px dotted #9A9A9A;
	height: 45px;
	display: flex;
	align-items: center;
	justify-content: center;
	background: transparent;
	font-size: 14px;
	color: #000;
	border-radius: 5px;
}

.gender-buttons button:hover {
	border: 2px solid #000;
	background: #000;
	color: #fff;
}

.gender-buttons button.active {
	border: 2px solid #000;
	background: #000;
	color: #fff;
}

.auth-profile-pic-wrp h6 {
	font-size: 14px;
	margin-top: 15px;
	font-weight: 500;
	color: #000;
	margin-bottom: 0px;
}

.auth-profile-pic-wrp img {
	height: 60px;
	border-radius: 60px;
	width: 60px;
	object-fit: cover;
}

.get-started-buttons button {
	background: #F7F7F7;
	width: 320px;
	margin-bottom: 30px;
	height: 45px;
	border-radius: 5px;
	font-size: 14px;
}

.get-started-buttons {
	margin-top: 50px;
}

.record-buttons {
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 15px;
}

.record-buttons button {
	width: 100%;
	height: 45px;
	display: flex;
	align-items: center;
	justify-content: center;
	background: #F5F5F5;
	font-size: 14px;
	color: #000;
	border-radius: 5px;
}

.record-buttons img {
	margin-right: 5px;
}

.upload-doc-field input {
	width: 100%;
}

.artifical-doc-feild {
	width: 100%;
	background: whitesmoke;
	height: 45px;
	border-radius: 5px;
	position: relative;
	border: 1px solid #e3e3e3;
}

.artifical-doc-feild img {
	position: absolute;
	top: 8px;
	right: 10px;
	cursor: pointer;
}

.upload-doc-field input {
	position: absolute;
	z-index: 9;
	opacity: 0;
	cursor: pointer;
}

.auth-input-wrp a {
	font-size: 12px;
	float: right;
	padding-top: 10px;
	color: #0045FF;
	font-weight: 400;
}

.breadcrumb-section {
	background: url("/public/assets/img/breadcrumb-bg.png");
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	padding: 100px 0px;
}

.breadcrumb-content h1 {
	color: #fff;
	font-size: 36px;
	margin-bottom: 15px;
	font-weight: 600;
}

.breadcrumb-content ul {
	list-style: none;
	padding-left: 0px;
	margin-bottom: 0px;
}

.breadcrumb-content ul li {
	display: inline-block;
	opacity: 0.5;
}

.breadcrumb-content ul li a {
	color: #fff;
	font-size: 16px;
}

.breadcrumb-content ul li i {
	color: #fff;
	padding-left: 2.5px;
	padding-right: 12.5px;
	font-size: 12px;
}

.breadcrumb-content ul li.active {
	opacity: 1;
}

.breadcrumb-search input {
	height: 45px;
	border-radius: 0px;
	border-color: #fff;
	font-size: 14px;
	color: #000;
}

.breadcrumb-search {
	display: flex;
	align-items: center;
	height: 100%;
	width: 100%;
	justify-content: end;
}

.breadcrumb-search input {
	height: 45px;
	border-radius: 0px;
	border-color: #fff;
	font-size: 14px;
	color: #000;
	letter-spacing: 0.2px;
	width: 400px;
}

.breadcrumb-search input::placeholder {
	font-size: 14px;
	color: #000;
}

.breadcrumb-search button {
	background: #022742;
	border-radius: 0px;
	position: absolute;
	top: 0;
	height: 45px;
	width: 45px;
	right: 0;
}
.item{
	padding: 20px 10px;
}

.pratice-area-box {
	padding: 15px;
	border-radius: 25px;
	display: flex;
	align-items: center;
	padding: 20px 0;
	margin-bottom: 22px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.legal-practice-area 
{
	height: 380px;
	box-shadow: rgba(144, 144, 238, 0.2) 0px 2px 12px, rgba(78, 78, 78, 0.076) 0px 3px 17px;
}
.legal-h3{
	font-size: 48px;
	font-weight: 600;
}
.legal-paragraph 
{
	font-size: 24px;
	margin-top: 10px;
	color: #717070;
}
.pratice-area-owl .owl-item .legal-practice-area {
    background: white ;
}
.pratice-area-box img {
	height: 70px;
	width: 70px !important;
	object-fit: cover;
	border-radius: 10px;
}
.pratice-area-box {
	border-radius: 25px;
	display: flex;
	align-items: center;
	transition: all 0.4s ease;
}
.premium-expert-content ul li {
    font-size: 18px;
    color: whitesmoke;
    margin: 14px 0;
}
.pratice-area-box:hover{
	background: #02daa827 !important;
	transform: translateY(-12px);
}

.pratice-area-box h4 {
	font-size: 16px;
	font-weight: 500;
	padding: 15px;
	margin-bottom: 0px;
	text-align: center;
	color: #000;
}

.img-content{
	display: flex;
	justify-content: center;
	margin-top: 15px;
}

.pratice-area-wrp {
	padding: 100px 0px;
	position: relative;
	border-radius: 20px;
}

.pratice-area-owl .owl-nav span {
	display: none;
}

.pratice-area-owl .owl-nav .owl-prev::before {
	content: "";
	background: url("/public/assets/img/owl-prev-icon.png");
	background-repeat: no-repeat;
	position: absolute;
	height: 40px;
	width: 80px;
	background-size: contain;
}

.pratice-area-owl .owl-nav .owl-next::after {
	content: "";
	background: url("/public/assets/img/owl-next-icon.png");
	background-repeat: no-repeat;
	position: absolute;
	height: 40px;
	width: 80px;
	background-size: contain;
}

.pratice-area-owl .owl-nav .owl-next {
	margin-left: 100px !important;
}

.pratice-area-owl .owl-nav .owl-next {
	position: absolute;
	top: 32%;
	right: 30px;
}

.pratice-area-owl .owl-nav .owl-prev {
	position: absolute;
	top: 32%;
	left: -3%;
}

.pratice-area-listing {
	padding-bottom: 100px;
}

.filter-listing ul {
	list-style: none;
	padding-left: 0px;
	margin-bottom: 0px;
	float: right;
}

.filter-listing ul li {
	margin-left: 15px;
	display: inline-block;
}

.filter-listing ul li select {
	height: 45px;
	border-radius: 5px;
	border: 2px solid #BFBFBF;
	font-size: 14px;
}

.filter-listing h3 {
	font-size: 24px;
	font-weight: 600;
	margin-bottom: 0px;
}

.filter-listing .row {
	align-items: center;
}

.exp-listing-con {
	padding-top: 50px;
}

.exp-listing-con-filter p {
	font-size: 14px;
	color: #000;
	font-weight: 500;
	margin-bottom: 0px;
}

.exp-listing-con-filter select {
	border: unset;
	width: auto;
	font-size: 14px;
	font-weight: 500;
}

.rec-filter-select {
	display: flex;
	align-items: center;
	justify-content: end;
}

.rec-filter-select span {
	font-size: 14px;
}

.exp-listing-box {
	overflow: hidden;
	border-radius: 10px;
	-webkit-box-shadow: -1px 15px 40px -10px rgba(0,0,0,0.32);
	-moz-box-shadow: -1px 15px 40px -10px rgba(0,0,0,0.32);
	box-shadow: -1px 15px 40px -10px rgba(0,0,0,0.32);
	margin-bottom: 30px;
	height: 94%;
}
.pratice-area-listing .page-item.active .page-link 
{
	background-color: #000 !important;
	color: #fff !important;
	border: none;
}
.pagination .page-item.active .page-link{
	background-color:#000 !important;
	color: #fff !important;	
	border: none;

}
.pagination .page-link 
{
	color: #000;
}
.pratice-area-listing .page-link 
{
	color: #000;
}
.pratice-area-listing .page-link:hover
{
	color: #000;
}
.pratice-area-listing .page-link:focus
{
	outline: none;
}
.pratice-area-listing .pagination 
{
	justify-content: center !important;
}
.exp-listing-content {
	padding: 25px;
}

.exp-listing-img {
	position: relative;
}

.exp-listing-img img {
	width: 100%;
	height: 200px;
	object-fit: cover;
}

.exp-listing-content h1 {
	font-size: 20px;
	font-weight: 600;
	margin-bottom: 10px;
}


.exp-listing-content h4 {
	font-size: 14px;
	margin-bottom: 10px;
	font-weight: 500;
	color: #3E3E3E;
}

.star-rating-text {
	display: flex;
	align-items: center;
}

.star-rating-text h4 {
	margin-bottom: 0px !important;
    font-size: 19px;
    padding-left: 5px;
    padding-top: 7px;
}

.exp-listing-content p {
	font-size: 12px;
	margin-top: 5px;
	color: #3E3E3E;
	font-weight: 400;
}

.exp-listing-content ul {
	list-style: none;
	padding-left: 0px;
	margin-bottom: 0px;
}

.exp-listing-content ul li {
	display: inline-block;
	margin-right: 15px;
}

.exp-listing-content ul li h3 {
	font-size: 14px;
	margin-bottom: 0px;
}

.exp-listing-content button {
	width: 100%;
	background:#022742;
	margin-top: 25px;
	height: 50px;
	font-size: 16px;
	color: #fff;
}

.exp-listing-content button:hover {
	background: #fff;
	border: 2px solid #022742;
	color: #022742;
}

.exp-listing-con-filter {
	margin-bottom: 30px;
}

.common-pagination ul {
	list-style: none;
	padding-left: 0px;
	margin-bottom: 0px;
	float: right;
}

.common-pagination ul li {
	display: inline-block;
	margin-right: 7.5px;
}

.common-pagination ul li a {
	background: transparent;
	padding: 10px;
	height: 45px !important;
	width: 45px !important;
	border: 1px solid #BFBFBF;
	border-radius: 5px;
	display: flex;
	align-items: center;
	justify-content: center;
	color: #000000;
	font-size: 16px;
}

.common-pagination ul li a i {
	font-size: 22px;
}

.common-pagination ul li.active a {
	background: #E4F7F3;
}

.common-pagination ul li:first-child {
	position: relative;
	top: 2.5px;
	margin-right: 20px;
}

.common-pagination ul li:last-child {
	position: relative;
	top: 2.5px;
	margin-left: 20px;
}

.expert-box-wrp.blue-bg {
	background: #022742;
}

.exp-profile-wrp {
	position: relative;
	top: -50px;
}

.exp-profile-box {
	box-shadow: -4px 12px 29px 1px rgba(145,145,145,0.53);
	-webkit-box-shadow: -4px 12px 29px 1px rgba(145,145,145,0.53);
	-moz-box-shadow: -4px 12px 29px 1px rgba(145,145,145,0.53);
	border-radius: 15px;
	padding: 25px;
	background: #fff;
}
.exp-profile-detail-row ul {
	list-style: none;
	padding-left: 0px;
	margin-bottom: 0px;
	display: flex;
	align-items: center;
	justify-content: center;
}

.exp-profile-detail-row ul li {
	border-right: 1px solid #D6D6D6;
	padding-right: 25px;
	margin-right: 25px;
	text-align: left;
	min-height: 65px;
	display: flex;
	align-items: center;
}

.exp-profile-detail-row ul li:last-child {
	border-right: unset !important;
}

.exp-user-dt {
	display: flex;
	align-items: center;
}

.exp-user-dt img {
	margin-right: 15px;
}

.exp-user-dt img {
	height: 65px;
	width: 65px;
	object-fit: cover;
	border-radius: 40px;
}

.exp-profile-detail-row h4 {
	font-size: 16px;
	margin-bottom: 5px;
}

.exp-profile-detail-row p {
	margin-bottom: 0px;
	font-size: 12px;
	font-weight: 400;
}

.exp-intro {
	display: flex;
	align-items: center;
	justify-content: start;
}

.exp-intro img {
	background: #E4F7F3;
	padding: 12.5px;
	margin-right: 15px;
	border-radius: 30px;
	height: 50px;
	object-fit: contain;
}

.exp-profile-detail-row {
	border-bottom: 1px solid #D6D6D6;
	margin-bottom: 5px;
	padding-bottom: 25px;
}

.exp-communicate-btn-row {
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 35px 70px;
}

.exp-communicate-btn-row button {
	border: 1px solid #000;
	padding: 15px;
	width: 250px;
	height: 45px;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 14px;
	margin-right: 30px;
}

.exp-communicate-btn-row button:hover {
	background: #000;
	color: #fff;
}

.exp-communicate-btn-row button:hover img {
	filter: brightness(0) invert(1);
}

.exp-communicate-btn-row button img {
	width: 15px;
	margin-right: 15px;
	object-fit: contain;
}

.exp-profile-detail-row .star-rating-text p {
	font-weight: 400;
	margin-left: 5px;
}

.continue-booking-btn button {
	border: 1px solid #000;
	padding: 15px;
	width: 100%;
	height: 45px;
	display: flex;
	align-items: center;
	justify-content: center;
	background: #000;
	color: #fff;
	font-size: 14px;
}

.continue-booking-btn button:hover {
	background: transparent;
	color: #000;
}

.continue-booking-btn {
	padding: 15px 70px;
}

.exp-wallet-amount p {
	text-align: center;
	margin-bottom: 0px;
	margin-top: 10px;
	font-size: 14px;
	font-weight: 500;
	color: #3E3E3E;
}

.exp-wallet-amount a {
	color: #1400FF;
}

.book-appointment-box {
	width: 100%;
	text-align: left;
}

.book-appointment-box h1 {
	font-size: 20px;
	font-weight: 600;
	margin-bottom: 15px;
}

.book-app-box {
	background: #E4F7F3;
	padding: 25px;
	border-radius: 15px;
	margin-bottom: 30px;
}

.book-app-box select {
	height: 45px;
	font-size: 13px;
	border-radius: 10px;
	border: 1px solid #EAEAEA;
}

.book-app-box input {
	height: 45px;
	font-size: 13px;
	border-radius: 10px;
	border: 1px solid #EAEAEA;
	margin-bottom: 0px;
	background: #fff;
}

.book-appointment-box h4 {
	margin-bottom: 0px;
}

.book-app-box h4 a {
	font-size: 14px;
}

.authentication-modal .btn-close {
	position: absolute;
	right: 15px;
	top: 15px;
	font-size: 12px;
}

.breadcrumb-content h2 {
	font-size: 36px;
	padding-top: 15px;
	margin-bottom: 0px;
}

.common-content-page {
	padding: 100px 0px;
}

.common-content-page p {
	font-size: 14px;
	line-height: 26px;
	color: #000;
	font-weight: 400;
}

.common-content-page ul {
	padding-left: 15px;
	list-style: decimal;
	margin-bottom: 0px;
}

.common-content-page h3 {
	font-size: 18px;
	font-weight: 600;
	padding-top: 15px;
}

.error-img img {
	width: 100%;
	object-fit: cover;
}

.error-img {
	padding-top: 100px;
	padding-bottom: 25px;
}

.page-not-found-content h3 {
	font-size: 22px;
	font-weight: 600;
}

.page-not-found-content p {
	font-size: 14px;
	margin-bottom: 10px;
}

.page-not-found-content button {
	border: 1px solid #022742;
	padding: 10px 36px;
	font-size: 14px;
	color: #022742;
	margin-top: 10px;
	font-weight: 500;
}

.page-not-found-content button:hover {
	border: 1px solid #022742;
	background:#022742;
	color: #fff;
}

.page-not-found-content {
	text-align: center;
}

.nav-shadow {
	box-shadow: 0px 0px 5px 0 #cacaca;
	position: relative;
	z-index: 9999;
}

/* admin pages css start */

.admin-wrapper {
	display: flex;
	margin-bottom: 50px;
}

.sidebar-wrapper {
	width: 325px;
	background: #EFF8F6;
	padding: 0px;
	height: calc(100vh - 101px);
	overflow-y: auto;
	position: fixed;
	left: 0;
	top: 95px;
	z-index: 99;
}

.admin-content-wrapper {
	width: calc(100% - 325px);
	margin-left: 325px;
	padding: 50px;
	margin-top: 95px;
	padding-bottom: 0px !important;
}

.user-img-sidebar {
	text-align: center;
	padding: 40px;
}

.user-img-sidebar img {
	height: 75px;
	width: 75px;
	border-radius: 80px;
	object-fit: cover;
}

.user-img-sidebar h3 {
	font-size: 16px;
	padding-top: 15px;
	margin-bottom: 0px;
	color: #000;
	font-weight: 600;
}

.sidebar-wrapper li {
	padding: 15px 30px;
}

.sidebar-wrapper li a {
	color: #000000;
	font-size: 14px;
	font-weight: 500;
}

.sidebar-wrapper li.active {
	background: #000;
}

.sidebar-wrapper li.active a {
	color: #fff;
}

.sidebar-wrapper li img {
    height: 20px;
    margin-right: 10px;
}

.sidebar-wrapper li.active img {
	filter: brightness(0) invert(1);
}

.btn.sidebar-mb-toggle-btn img {
	height: 20px;
	object-fit: contain;
	filter: brightness(0) invert(1);
	margin-right: 5px;
}

.btn.sidebar-mb-toggle-btn {
	background: #165d51;
	height: 50px;
	color: #fff;
	font-size: 14px;
	width: 100vw;
	display: flex;
	align-items: center;
	border-radius: 0px;
	justify-content: center;
}

.sidebar-toggle {
	position: absolute;
	z-index: 999;
	top: 95px;
	left: 0px;
	display: none;
}

.update-pp-wrp {
	display: flex;
	align-items: center;
	height: 100%;
	justify-content: start;
	position:relative;
}

.upt-pp-img img {
	height: 75px;
	width: 75px;
	object-fit: cover;
	border-radius: 100px;
}

.update-pp-content p {
	margin-bottom: 0px;
	font-size: 16px;
	font-weight: 400;
	color: #000;
}

.update-pp-content {
	height: 100%;
	display: flex;
	align-items: center;
	display: flex;
	justify-content: space-between;
	padding-left: 25px;
	width: 100%;
}

.upt-edit-icon {
	background: #000;
	padding: 10px;
	border-radius: 50px;
	height: 45px;
	width: 45px;
	display: flex;
	align-items: center;
	justify-content: center;
}

.update-per-details-wrp {
	padding: 50px 0px;
}

.update-per-details-wrp label {
	color: #B9B9B9;
	margin-bottom: 10px;
	font-size: 14px;
	font-weight: 400;
}

.update-per-details-wrp input {
	height: 45px;
	background: #F5F5F5;
	border: 1px solid #E3E3E3;
	border-radius: 5px;
	margin-bottom: 15px;
	font-size: 14px;
}

.input-date {
	position: relative;
}

.input-date img {
	position: absolute;
	right: 13px;
	top: 13px;
	height: 20px;
	background: #f5f5f5;
	object-fit: contain;
}

.add-new-input {
	display: flex;
	align-items: center;
	justify-content: start;
}

.add-new-input a {
	white-space: nowrap;
}

.add-new-input a {
	white-space: nowrap;
	display: block;
	position: relative;
	top: -8px;
	font-size: 14px;
	padding-left: 15px;
	color: #0014FF;
}

.navigation.admin-nav {
	position: fixed;
	width: 100%;
	top: 0;
	left: 0;
	right: 0;
}

.btn.btn-grey-common {
	background: #000;
	width: 100%;
	height: 50px;
	font-size: 16px;
	font-weight: 500;
	color: #fff;
	margin-top: 10px;
	border-radius: 8px;
}

.appointment-tabs .nav-item button {
	background: #fff;
	width: 125px;
	color: #7F7F7F;
	border: 1px solid #707070;
	margin-right: 15px;
	margin-bottom: 15px;
	height: 45px;
	font-size: 14px;
}

.appointment-tabs .nav-item button.active {
	background: #095D52;
	border-color: #095D52;
	color: #fff;
}

.appointment-tabs .nav-item button:hover {
	background: #095D52;
	color: #fff;
	border-color: #095D52;
}

.common-table-wrapper {
	margin-top: 30px;
}

.appointment-tabs ul {
	margin-bottom: 0px;
}

.table-user {
	display: flex;
	align-items: center;
	justify-content: start;
}

.common-table-wrapper h5 {
	margin-bottom: 5px;
	font-size: 14px;
	color: #000;
	font-weight: 600;
	white-space: nowrap;
}

.common-table-wrapper h6 {
	margin-bottom: 5px;
	font-size: 14px;
	color: #000;
	font-weight: 500;
	white-space: nowrap;
}

.table-user img {
	height: 50px;
	width: 50px;
	object-fit: cover;
	margin-right: 15px;
	border-radius: 50px;
}

.common-table-wrapper p {
	margin-bottom: 0px;
	font-size: 13px;
	font-weight: 400;
	white-space: nowrap;
}

.common-table-wrapper td:last-child {
	text-align: left;
}

.table-btn-group button {
	background: transparent;
	border: 2px solid #D1D1D1;
	width: 135px;
	font-size: 14px;
	height: 40px;
	margin-left: 15px;
	font-weight: 500;
}

.table-btn-group button:hover {
	background: #000;
	border: 1px solid #000;
	color: #fff;
}

.common-table-wrapper tr {
	vertical-align: middle;
	border-radius: 5px;
	border-bottom: 0px;
}

.common-table-wrapper tr td {
	border-bottom: unset !important;
	padding: 15px;
	background: #F2F4F7;
	margin-bottom: 10px;
}

.common-table-wrapper table {
	border-collapse: separate;
	border-spacing: 0 1em;
}

.btn-outline-danger {
	color: #F50C1A !important;
	border-color: #F50C1A !important;
}

.btn-outline-danger:hover {
	color: #fff !important;
	background: #F50C1A !important;
}

.table-btn-group {
	display: flex;
	justify-content: end;
	align-items: center;
}

.appointment-details-modal-wrp {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-top: 15px;
	margin-bottom: 15px;
}

.appointment-deatils-box h1 {
	font-size: 18px;
	text-align: left;
	margin-bottom: 25px;
	color: #000;
}

.app-user-wrp img {
	height: 60px;
	width: 60px;
	border-radius: 60px;
	margin-right: 15px;
	object-fit: cover;
}

.app-user-wrp {
	text-align: left;
}

.appointment-details-modal-wrp h4 {
	font-size: 16px;
	margin-bottom:5px;
	font-weight: 500;
}

.appointment-details-modal-wrp h6 {
	font-size: 18px;
	margin-bottom: 0px;
	color: #D6D6D6;
	font-weight: 500;
}

.app-user-wrp {
	display: flex;
	justify-content: start;
	align-items: center;
}

.appointment-details-modal-wrp p {
	font-size: 14px;
	color: #aaa;
	margin-bottom: 5px;
	text-align: left;
}

.appointment-details-modal-wrp h3 {
	font-size: 16px;
	color: #000000;
	margin-bottom: 0px;
}

.app-agenda-box {
	text-align: left;
	background: #E4F7F3;
	padding: 25px;
	border-radius: 10px;
	margin-top: 15px;
	position: relative;
	height: 100%;
}

.app-agenda-box h3 {
	font-size: 16px;
	margin-bottom: 10px;
}

.app-agenda-box p {
	font-size: 14px;
	color: #B2B2B2;
	margin-bottom: 0px;
	line-height: 185%;
	text-align: justify;
}

.app-agenda-box a {
	position: absolute;
	right: 0;
}

.app-agenda-box a {
	position: absolute;
	right: 25px;
	font-size: 16px;
	color: #000AFF;
}

.app-agenda-box h2 {
	font-size: 24px;
	margin-top: 15px;
}

.modal-footer-btn-group {
	display: flex;
	justify-content: end;
	align-items: center;
	padding-top: 25px;
}

.modal-footer-btn-group button {
	background: transparent;
	border: 2px solid #D1D1D1;
	width: 135px;
	font-size: 14px;
	height: 40px;
	margin-left: 15px;
	font-weight: 500;
}

.modal-footer-btn-group button:hover {
	background: #000;
	border: 1px solid #000;
	color: #fff;
}

.wallet-recharge-wrp {
	background: #F2F9F7;
	padding: 15px;
	border-radius: 5px;
	box-shadow: -7px 12px 9px -8px rgba(199,199,199,0.75);
	-webkit-box-shadow: -7px 12px 9px -8px rgba(199,199,199,0.75);
	-moz-box-shadow: -7px 12px 9px -8px rgba(199,199,199,0.75);
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.wallet-recharge-content img {
	background: #fff;
	padding: 15px;
	border-radius: 50px;
	height: 60px;
	object-fit: contain;
	margin-right: 25px;
}

.wallet-recharge-content {
	display: flex;
	align-items: center;
	justify-content: center;
}

.wallet-recharge-wrp h3 {
	font-size: 18px;
	font-weight: 500;
	margin-bottom: 5px;
}

.wallet-recharge-content h6 {
	color: #8D8D8D;
	font-size: 16px;
	margin-bottom: 0px;
	font-weight: 300;
}

.wallet-rec-button button {
	background: #000;
	border: 1px solid #000;
	color: #fff;
	width: 135px;
	height: 45px;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 14px;
	border-radius: 4px;
}

.wallet-rec-button button:hover {
	color: #000;
	background: transparent;
}

.common-table-wrapper thead th {
	font-size: 14px;
	font-weight: 400;
	color: #AFAFAF;
	padding: 10px;
	border-bottom: unset;
	white-space: nowrap;
}

.common-table-wrapper thead th img {
	margin-left: 5px;
}

.common-table-wrapper thead th img {
	height: 11px;
	cursor: pointer;
	object-fit: contain;
}

.action-btn-group button img {
	height: 18px;
	object-fit: contain;
}

.action-btn-group button {
	margin-left: 7.5px;
}

.action-btn-group {
	display: flex;
	align-items: center;
}

/* star animation css */

.exp-listing-img .star {
	display: block;
	text-align: center;
	font-size: 30px;
	position: absolute;
	top: 10px;
	right: 15px;
	cursor: pointer;
  }
  
  .exp-listing-img .star-icon {
	display: block;
	transition: all .2s ease-in;
	color: #000;
  }
  
  .exp-listing-img .starred .star-icon {
	color: #f6c342;
  }
  
  .exp-listing-img .starred {
	animation: spin .6s forwards;
	transform-origin: 50% 50%;
	transition-timing-function: ease-in-out;
  }
  
  .exp-listing-img .starred .star-icon {
	animation: spin .6s forwards;
	transform-origin: 50% 50%;
	-webkit-transform-origin: 50% 50%;
	transition-timing-function: ease-in-out;
  }
  
  .exp-listing-img .unstarred .star-icon {
	animation: unspin .3s forwards;
	transform-origin: 50% 50%;
	-webkit-transform-origin: 50% 50%;
	transition-timing-function: ease-in-out;
  }
  
  @keyframes spin {
	0% {
	  transform: rotate(0deg);
	}
	33% {
	  transform: rotate(175deg);
	}
	66% {
	  transform: rotate(120deg);
	}
	100% {
	  transform: rotate(144deg);
	}
  }
  
  @keyframes unspin {
	0% {
	  transform: rotate(142deg);
	}
	100% {
	  transform: rotate(72deg);
	}
  }
  
  @keyframes hop {
	0% {
	  transform: translate(0, 0px);
	}
	17% {
	  transform: translate(2px, -10px);
	}
	33% {
	  transform: translate(4px, 0px);
	}
	50% {
	  transform: translate(-2px, -7px);
	}
	66% {
	  transform: translate(-4px, -0px);
	}
	84% {
	  transform: translate(-2, -5px);
	}
	100% {
	  transform: translate(0, 0px);
	}
  }

  .req-chat-icon img {
	height: 22.5px;
	object-fit: contain;
	cursor: pointer;
  }

  .req-chat-icon {
	width: 100%;
	text-align: right;
}

/*------- borhan app modal-------------*/
.brohan-app-icon
{
	padding-right: 10px;
}
.getapp .small-text
{
  font-size:11px;
}

.getapp .phone-number
{
	font-size: 11px;
}

.getapp  input::-webkit-input-placeholder {
    font-size: 11px;
}

.getapp .download-app-tagline 
{
   border-bottom: 2px solid #2F3231;
   border-width: 50%;

}

.getapp .send-number
{
	font-size: 11px;
}

.getapp .get-app-banner
{
	background-color: #00FFD7;
	text-align: center;
    display: flex;
    align-items: center;
    justify-content: center
}

.getapp .get-app-banner img
{
	padding: 50px 20px 0 0;
}

.getapp .modal-dialog {
    max-width: 900px;
}
@media(max-width:1200px)
{
   .text-center-md
   {
	   text-align: center;;
   }

}
@media(max-width:1024px)
{
   .get-app-banner img
   {
	   display: none;
   }
}

/*------------------------ membership plans page design css-------------------*/
.membership-plans-table td p, .membership-plans-table th p
{
   font-size: 22px;
   font-weight: 600;
}
.membership-table-row td p, .membership-table-row td img{
	margin-top:35px;
}
.faqs h4{
	margin-top:30px;
}
.faqs p
{
	color: #A4A4A4;
	font-size:20px;
	margin-top: 18px;
}

@media(max-width:767px)

{   .membership-plans-table td p, .membership-plans-table th p
	{
	   font-size: 16px;
	   font-weight: 600;
	}
	
	.membership-plans-table  .check-img{
		width:25px;
	}
}

/*---------------contact us page----------------*/
.contact-us input[type="text"], .contact-us input[type="email"]
		{
			border-radius:20px;
			color: #000;
		}

		.contact-us .contact-tagline
		{
			font-size:20px;
			line-height: 2;
		}
		.contact-us .contact-me
		{
			background-color: #000;
			width: 100%;
			border-radius: 20px;
			color:#fff;
			padding: 8px;
		}
		.contact-us .contact-me:hover
		{
			opacity: .9;
		}
		.contact-us .list-address
		{
			margin: 0;
			padding: 0;
		}
		.contact-us .list-address li
		{
			list-style-type: none;
			
		}
		.contact-us .list-address li p
		{
			list-style-type: none;
			
		}
		.contact-us .list-address li a
		{
			text-decoration: none;
		}
		.contact-us .list-address li a:hover
		{
			opacity: .9;
		}
		.contact-us .contact-details-inner
		{
			padding: 49px 27px;
    		margin-right: 10px;
    		height: 100%;
    		box-shadow: 0 0.5rem 1rem rgb(0 0 0 / 7%);
		}
		.contact-us .contact-details-inner:hover{
			box-shadow: 0 0.9rem 1rem rgb(0 0 0 / 10%);
		}
	   .contact-us .contact-form
		{
			width:50%;
		}
		.contact-form-img{
    		padding-top: 16%;
		}
		.more-contact-details
		{
			margin-top:60px;
		}
		@media(max-width:1200px)
		{
			.contact-us .contact-form
			{
				width:100%;
			}
			.contact-form-img{
				padding-top: 53%;
			}
		}
		@media(max-width:991px)
		{
			.contact-us .contact-form
			{
				width:100%;
			}
			.contact-form-img{
				display: none;
			}
		}

		/*-----------------manage membership ------------*/
	
		.manage-membership-table th{
			border-bottom: unset !important;
			background: #F2F4F7;
			margin-bottom: 10px;
	}
	.manage-membership-table th h5{
		font-size: 14px;
		color: #000;
		font-weight: 600;
		white-space: nowrap;
		padding:10px 0 6px 10px ;
	}
	.membership-table-wrapper tr td{
		padding: 15px 0 20px 15px;
		background: #fff !important;
		margin-bottom: 10px;
		border-bottom: 1px solid #D5D5D5 !important;
	}

	.owl-carousel.health-owl .owl-nav.disabled,
	.owl-carousel.health-owl .owl-dots.disabled {
	  display: flex !important; 
	}
	
	.navbar-nav li a:hover{
		cursor:pointer;
	}
.btn-audio
{
	position: relative;
	width: 47.5%;
}
.audio-file
{
	position: absolute;
	top:0;
	opacity: 0;
	width: 100%;
}
.btn-video
{
	position: relative;
	width: 47.5%;
}
.video-file
{
	position: absolute;
	top: 0;
	opacity: 0;
	width: 100%;
}

.profile-pic-chooose
{
	position:relative;
}

.hide-input
{
	position:absolute;
	top:11px;
	right:81px;
	opacity:0;
}

.profile-pic-input{
	position:absolute;
	left:0;
    opacity:0;	
}
.search-practice-area{

	background: #fff;
	position: absolute;
	z-index: 99;
	width: 100%;
	top: 54px;
	padding: 15px 10px;
	box-shadow: 10px 24px 37px -17px #c2c2c2;
	border: 1px solid #f2eeee;
	border-radius: 5px;
    
}
.search-practice-area ul li:hover {
    color: #65c9ba;
}
.search-practice-area ul{
	
	list-style: none;
	padding-left: 5px;
	max-height:600px;
	margin-bottom: 0;
	overflow-y:auto;
}
ul.blocklist {
    display: block;
}
.search-practice-area{
}
/* .display-value{
	WIDTH: 100%;
    FLOAT: LEFT;
    OVERFLOW: HIDDEN;
    word-break: break-all;
} */
.btn-appointment
{	
    padding: 5px 47px;

}
.react-tel-input{
	margin-bottom: 15px;
}
.btn-appointment1{
	margin-bottom:7px;
	padding-top: 7px;
}

 .chatroom {
    background: transparent;
    border: 2px solid #D1D1D1;
    width: 40px !important;
    font-size: 14px;
    height: 40px !important;
    border-radius: 55px !important;
    margin-left: 15px;
    font-weight: 500;
}
.td-disable
         {
           opacity: .5;
         }

		 
.custm-loader {
  width: 100%;
  height: 100vh;
  position: fixed;
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f0f2f51a;
  backdrop-filter: blur(2px);
  overflow: hidden;
  /* padding-right: 230px; */
}
.react-tel-input .form-control{
	height: 55px !important;
	width: 100% !important;
}
.custm-loader-modal{
	width: 100%;
	height: 100vh;
	position: fixed;
	z-index: 9999999;
	display: flex;
	align-items: center;
	justify-content: center;
	background: #f0f2f51a;
	backdrop-filter: blur(2px);
	overflow: hidden;
	/* padding-right: 230px; */
  }

.react-calendar__tile--active {
    background: #000 !important;
    color: white;
}
.react-calendar 
{
	border:1px solid #000;
}
.react-calendar__navigation 
{
	background-color: #000 !important;
}
.react-calendar__navigation button {
    color: #fff !important;
    font-weight: bolder !important;
}
.table-content-align 
{
	text-align: center;
	padding-top: 20px;
}
.exp-listing-img span 
{
	position:absolute;
	top:5px;
	padding-left:20px;
}
.exp-listing-img .star 
{
    right:56px;
	top:0;
}
.expert-box-wrp  h4 
{
	color: #fff;
	font-size: 22px;
}
.main-bn-feilds ul li .blocklist li {
    background: #8080800f;
    padding: 5px 5px 5px 20px;
    margin-bottom: 0;
    border-bottom: 1px solid #f1f1f1;
    font-size: 14px;
    line-height: 18px;
    position: relative;
}

.main-bn-feilds ul li .blocklist {
    list-style: decimal !important;
    padding: 0 !important;
}

.main-bn-feilds ul li .blocklist li::after {
    content: '';
    position: absolute;
    left: 5px;
    width: 5px;
    height: 5px;
    background: #000000;
    border-radius: 50%;
    top: 50%;
    transform: translate(0px, -50%);
}
.search-practice-area {
    padding: 0 !important;
}
.user-detail {
    min-height: 150px;
}
.user-detail h4 , .user-detail p {
    min-height: 20px;
}
.cardsbar {
    padding: 20px;
    border-radius: 20px;
    position: relative;
    margin-bottom: 30px;
    background-image: linear-gradient(159deg, #07322d, rgb(72 245 206 / 63%));
}

.cardsbar .head {
    margin-bottom: 20px;
}
.cardsbar .head h4 {
    color: #388b7e;
    text-transform: uppercase;
    text-align: left;
    font-weight: 500;
    font-size: 20px;
}
.cardsbar::after {
    content: '';
	background-image: url('../../../public/assets/img/main-logo.png');
	width: 155px;
    height: 145px;
    position: absolute;
    top: 0;
    right: 0;
    opacity: .2;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: left center;
}
.cardsbar .chipbar img {
    width: 50px;
}
.cardsbar h4.card-no {
    margin-top: 15px;
    color: #ffffff;
}
.cardsbar .cardname h6 {
    text-align: left;
    padding-left: 30%;
    font-size: 10px;
    color: #000000;
    display: flex;
    align-items: center;
}
.cardsbar .cardname h6 span {
    color: #ffffff;
    font-size: 18px;
    margin-left: 10px;
}
.cardsbar .cardname h3 {
    color: #ffffff;
    font-size: 24px;
    letter-spacing: 2px;
    font-weight: bold;
    text-transform: uppercase;
}
.cardsbar .cardname h3 {
    color: #ffffff;
    font-size: 20px;
    letter-spacing: 1px;
    font-weight: normal;
    text-transform: uppercase;
    margin: 0;
}
.cardsbar .brand {
    position: absolute;
    bottom: 15px;
    right: 20px;
}
.cardsbar .brand h5 {
    margin: 0;
    font-size: 30px;
    text-transform: uppercase;
    color: #176157;
    font-style: italic;
    font-weight: bold;
}